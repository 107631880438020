import React, {useEffect} from 'react';
import {GrCli} from "react-icons/gr"
import {RiExternalLinkFill} from "react-icons/ri";
import AOS from 'aos'
import 'aos/dist/aos.css'
import project_1 from '../assets/Projects Icon/1. Cryobag.png'
import project_2 from '../assets/Projects Icon/2. Solar tracking.png'
import project_3 from '../assets/Projects Icon/3. Renwable Energy.png'
import logo from "../assets/White/White/8. PROJ.png";
const Education = () => {
    useEffect(()=>{
        AOS.init()
    },[])
    return (
        <div name='projects'  className= 'w-screen pt-4  h-screen bg-[#0a192f] text-gray-300'>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000" className=' max-w-[1000px] pt-14 mx-auto flex flex-col w-full h-full'>
                <div>
                    <img className='-mb-8 sm:-mb-12 pl-2 sm:w-[60px] w-[40px]  'src={logo} alt={logo} ></img>
                    <p className='sm:pl-20 pl-14 text-xl sm:text-4xl font-bold inline text-white '>Projects</p>
                </div>
                <div className=' sm:px-2 px-4 p-2  w-full grid grid-cols-2 sm:grid-cols-3 gap:2 sm:gap-4 text-center pt-8'>
                    <div className=' bg-[#0d273f] pl-2 pb-2 hover:scale-125  text-left group shadow-md shadow-[#040c16] duration-500'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='https://drive.google.com/file/d/19o4kHgL8y4V4K74uq4RQgnDB1XiuMw_e/view?usp=sharing' target='_blank'
                            rel="noopener noreferrer" >
                            <RiExternalLinkFill className=' ml-36 sm:ml-[280px] mt-2 hover:scale-125 scale-100 '>
                            </RiExternalLinkFill>
                        </a>

                        <img  className='-mt-4 ml-2 w-[40px] ' src={project_1} alt='html'  ></img>
                        <p className='my-4 pt-4 pl-2 text-lg sm:text-2xl font-semibold'>Cryobag</p>
                        <ul className='text-[8px] sm:text-xs '>
                            <li className='p-2 pt-4'>
                                A project received funding and training through the "Makerspace Program" from BRAC SIL and the Internet Society.
                            </li>

                            <li  className='p-2'>
                                It used HCD procedures and conducted field studies in remote coastal areas, and an action plan was made to develop a working prototype
                            </li>
                        </ul>
                    </div>

                    <div className='bg-[#0d273f] text-left group shadow-md shadow-[#040c16] duration-500 hover:scale-110 '>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='https://drive.google.com/file/d/1096pP9vD5Mc6c8zdixQlYtIfdSdhCzYy/view?usp=sharing' target='_blank'
                            rel="noopener noreferrer" >
                            <RiExternalLinkFill className='ml-36 sm:ml-[280px] mt-2 hover:scale-125 scale-100 '>
                            </RiExternalLinkFill>
                        </a>


                        <img  className='-mt-4 ml-2 w-[40px] ' src={project_2} alt='html'  ></img>


                        <p className='my-4 pl-2 text-lg sm:text-2xl font-semibold'> Autonomous Solar Tracking System</p>
                        <ul className='text-[8px] sm:text-xs'>
                            <li className='pt-1 p-2'>
                                The IEE funded a project to improve solar panel efficiency, supervised
                                by Dr. Hamidur Rahman and Dr. Ahsan Habib.</li>
                            <li className='p-2'>It was a runner-up in the IUT project competition</li>
                        </ul>
                    </div>


                    <div className=' pt-8 sm:pt-0 bg-[#0d273f] text-left group shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='https://drive.google.com/file/d/18XASpZe9wk8sGjpPZac4e30a9JN3E7I4/view?usp=sharing' target='_blank'
                            rel="noopener noreferrer" >
                            <RiExternalLinkFill className='ml-36 sm:ml-[280px] mt-2 hover:scale-125 scale-100 '>
                            </RiExternalLinkFill>
                        </a>

                        <img  className='-mt-4 ml-2 w-[40px] ' src={project_3} alt='html'  ></img>
                        <p className='my-4 pl-2 text-lg sm:text-2xl font-semibold'> Renewable Energy Scenario in Bangladesh</p>
                        <ul className='text-[8px] sm:text-xs '>
                            <li className='pt-1 p-2'>
                                Under Dr. Monjurul Ehsan's guidance,
                                the project collected data on Bangladesh's traditional renewable energy sectors.
                                </li>
                            <li className='p-2'>
                                Conducted a field study in Sirajganj Wind Turbine Power Project,
                                and visualized the data to understand the present status.</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default Education;
