import React, {useEffect} from 'react';
import photo_1 from '../assets/Portfolio Updated Pics/1.jpg'
import photo_2 from '../assets/Portfolio Updated Pics/2.jpg'
import photo_3 from '../assets/Portfolio Updated Pics/3.jpg'
import photo_4 from '../assets/Portfolio Updated Pics/4.jpg'
import photo_5 from '../assets/Portfolio Updated Pics/new_05.png'
import photo_7 from '../assets/Portfolio Updated Pics/6.jpg'
import photo_8 from '../assets/Portfolio Updated Pics/7.jpg'
import photo_9 from '../assets/Portfolio Updated Pics/8.jpg'
import photo_10 from '../assets/Portfolio Updated Pics/9.jpg'
import photo_11 from '../assets/Portfolio Updated Pics/10.jpg'
import photo_12 from '../assets/Portfolio Updated Pics/11.jpg'
import photo_13 from '../assets/Portfolio Updated Pics/12.jpg'
import photo_14 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/2.jpg'
import photo_15 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/3.jpg'
import photo_16 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/4.jpg'
import photo_17 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/6.jpg'
import photo_18 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/8.jpg'
import photo_19 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/9.jpg'
import photo_20 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/10.2.jpg'
import photo_21 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/11.jpg'
import photo_22 from '../assets/Portfolio Updated Pics/Portfolio Pics Final/14.jpg'

import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../assets/White/White/9. Photo.png";

function Photo(props) {
    useEffect(() => {
        AOS.init()
    }, [])
    return (
        <div name='photo' className=' w-screen h-full bg-[#0a192f] text-gray-300 '>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000"
                 className=' max-w-[1000px] mx-auto px-4 flex flex-col justify-center  w-full h-full'>

                <div>
                    <img className='-mb-8 sm:-mb-12 pl-2 sm:w-[60px] w-[40px]  ' src={logo} alt={logo}></img>
                    <p className='sm:pl-20 pl-14 text-xl sm:text-4xl font-bold inline text-white '>Moments in Pixels</p>
                </div>
                <div className=' mt-4 w-full grid grid-cols-2 sm:grid-cols-3 gap-1 text-center py-4 pb-8'>
                    <div className='hover:scale-125 group  duration-500'>
                        <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_1} alt="HTML icon"/>
                    </div>

                    <div className='hover:scale-125 group  duration-500'>
                        <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_2} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_3} alt="HTML icon"/>
                    </div>

                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_4} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_5} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_7} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_8} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_9} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_10} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_11} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_12} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_13} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_14} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_15} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_16} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_17} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_18} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_19} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_20} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_21} alt="HTML icon"/>
                    </div>
                    <div className='hover:scale-125 group duration-500'>
                        <img className='w-{120px} h-{72px}  pt-{10px}' src={photo_22} alt="HTML icon"/>
                    </div>

                    <div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Photo;