import React, {useEffect} from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css'
import Nestle from "../assets/nestle.png";
import Gyre from "../assets/gyre.png";
import smartifier from "../assets/smartifier.png";
import logo from "../assets/White/White/6. Skills.png";
function Skills() {
    useEffect(()=>{
        AOS.init()
    },[])

    return (
        <div name='skills' className=' w-screen h-screen bg-[#0a192f] text-gray-300'>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000" className=' max-w-[1000px] mx-auto  flex flex-col justify-center w-full h-full'>
                <div >
                    <div>
                        <img className='-mb-8 sm:-mb-12 pl-2 sm:w-[60px] w-[40px]  'src={logo} alt={logo} ></img>
                        <p className='sm:pl-20 pl-12 text-xl sm:text-4xl font-bold inline text-white '>Skills</p>
                    </div>
                    <div className=' mt-4 pl-2 grid grid-cols-2 sm:grid-cols-3 sm:gap-24 gap-4 text-center  py-8'>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base '>Solidworks</p>
                            </div>
                            <div className='bg-[#daf4f7] w-[160px] sm:w-[320px] rounded-full'>
                                <div className="bg-[#07636e] h-2.5 rounded-full w-[135px] sm:w-[270px] "></div>
                            </div>
                        </div>

                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base '>Autocad</p>
                            </div>

                            <div className='bg-[#daf4f7]   w-[160px]  sm:w-[320px]  rounded-full bg-[#daf4f7]'>
                                <div className="bg-[#07636e] h-2.5 rounded-full w-[135px] sm:w-[270px]"></div>
                            </div>
                        </div>

                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base '>Ansys</p>
                            </div>

                            <div className='bg-[#daf4f7]   w-[160px]  sm:w-[320px]  rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full w-[135px] sm:w-[270px]"></div>
                            </div>
                        </div>

                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>Keyshot</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full'>
                                <div className="bg-[#07636e] h-2.5 rounded-full  w-[110px] sm:w-[220px] "></div>
                            </div>
                        </div>


                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base '>Fusion360</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full w-[90px] sm:w-[180px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base  '>MS Excel</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full w-[120px] sm:w-[240px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>MS Word</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full w-[140px]  sm:w-[280px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>MS Powerpoint</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full sm:w-[280px] w-[120px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>Google Workplace</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full  w-[100px] sm:w-[200px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>PowerBl</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full sm:w-[200px] w-[100px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>Tableau</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full sm:w-[180px] w-[90px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>Adobe Ecosystem</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full sm:w-[180px] w-[90px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>Python</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full sm:w-[200px] w-[100px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>Arduino</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full sm:w-[180px] w-[90px]"></div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <p className='text-white text-left sm:text-lg text-base'>MATLAB</p>
                            </div>
                            <div className='bg-[#daf4f7]    w-[160px] sm:w-[320px] rounded-full bg-[#daf4f7]  '>
                                <div className="bg-[#07636e] h-2.5 rounded-full w-[100px] sm:w-[200px]"></div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </div>


    );
}

export default Skills;