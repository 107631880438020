import React, {useEffect} from 'react';
import Documentation from "../assets/documentation.svg"
import AOS from 'aos'
import 'aos/dist/aos.css'
import pub1 from '../assets/Publication Icon/1.png'
import pub2 from '../assets/Publication Icon/2.png'
import logo from "../assets/White/White/4. Public.png";
function Publications() {
    useEffect(()=>{
        AOS.init()
    },[])
    return (
        <div name='publications' className='  w-screen h-screen bg-[#0a192f] text-gray-300'>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000" className=' max-w-[1000px] mx-auto  flex flex-col justify-center w-full h-full'>
                <div>
                    <img className='-mb-8 sm:-mb-12 pl-2 sm:w-[60px] w-[40px]  'src={logo} alt={logo} ></img>
                    <p className='sm:pl-20 pl-14 text-xl sm:text-4xl font-bold inline text-white '>Publications</p>
                </div>

                <div className=' mt-4 w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-4 gap-2 text-center py-8'>
                    <div className='px-8 sm:px-0 hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-20 mx-auto pt-{10px}' src={pub1} alt="HTML icon"/>
                        <p className='my-4 text-base sm:text-lg'>Sustainable Implementation of Green supply chain Management
                            in the RMG Sector of Bangladesh </p>
                        <div className="invisible group-hover:visible">
                            <ul className='pb-2'>

                                <li className="text-xs">The 3rd International Conference on Industrial & Mechanical
                                    Engineering and Operations Management (IMEOM)

                                </li>
                            </ul>
                        </div>
                        <a href="https://drive.google.com/file/d/1yg_8EBlT1G4M0B75Q40qCddL1wWN9gOm/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                            <button
                                className="mb-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                Publications Link
                            </button>
                        </a>

                    </div>
                    <div className=' hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-20 mx-auto pt-{10px}' src={pub2} alt="HTML icon"/>
                        <p className='my-4 text-base sm:text-lg'> Lead time optimization of RMG sector Supply Chain in Bangladesh </p>
                        <div className="invisible group-hover:visible">
                            <ul className="pb-2">
                                <li className="text-xs"> Bangladesh Institute of Human Resources Management (BIHRM)
                                    Supply Chain Insider
                                    ISSN 2617-7420, 4(1)
                                </li>
                            </ul>
                        </div>
                        <a href="https://drive.google.com/file/d/1YN6lZYJPYoCs33frJtbS1MZhr7uRgKUE/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                            <button
                                className="mb-4 py-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                Publication Link
                            </button>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default Publications;