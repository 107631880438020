import React, {useEffect} from 'react';
import photo_1 from '../assets/Article/article_logo.jpg'
import photo_2 from '../assets/Remarks/1.PNG'
import photo_3 from '../assets/Remarks/2.PNG'
import photo_4 from '../assets/Remarks/3.PNG'

import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, EffectFade, Navigation, Pagination} from "swiper";

import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../assets/White/White/chat.png";
function Article() {
    useEffect(()=>{
        AOS.init()
    },[])
    return (
        <div name='achievement' className='w-screen h-full bg-[#0a192f] text-gray-300 pt-24'>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000" >
                <div className='pl-4 sm:pl-96 pb-8'>
                    <img className='-mb-8 sm:-mb-10 pl-2 w-[40px] sm:w-[50px] 'src={logo} alt={logo} ></img>
                    <p className='pl-12 sm:pl-16 text-xl sm:text-3xl sm:text-4xl font-bold inline text-white '>Article</p>
                </div>
                <div className='sm:px-24' >

                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        spaceBetween={50}
                        slidesPerView={2}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2.5,
                        }}
                        pagination={{el: '.swiper-pagination', clickable: true}}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper_container"
                    >
                        <SwiperSlide>
                            <a href="https://drive.google.com/file/d/1kLpBPSIpIQ_bsAlkuwx2rtKduWrX3D6f/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                            <img className='sm:w-{120px} sm:h-{80px} sm:scale-100 scale-125 pt-{10px}' src={photo_1} alt="HTML icon"/>
                            </a>

                        </SwiperSlide>

                        <div className="slider-controler">
                            <div className="swiper-button-prev slider-arrow">
                                <ion-icon name="arrow-back-outline"></ion-icon>
                            </div>
                            <div className="swiper-button-next slider-arrow">
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </Swiper>
                </div>

            </div>
        </div>
    );
}

export default Article;

