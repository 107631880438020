import React, {useEffect, useState} from 'react';

import IUT from '../assets/Iut.png'
import Adamzee from '../assets/adamze.png'
import LAURENTIAN from '../assets/varsity-logo.png'
import AOS from "aos";
import 'aos/dist/aos.css'
import logo from '../assets/White/White/1. Edu.png'

const Education = () => {
    useEffect(()=>{
        AOS.init()
    },[])
    return (
        <div name='education' className='py-4 sm:pb-2 pb-64 w-screen h-full bg-[#0a192f] text-gray-300 hover:animation-bounce'>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000" className=' max-w-[1000px] mx-auto pr-4 flex flex-col justify-center w-full h-full'>
                <div>
                    <img className='-mb-8 sm:-mb-12 pl-2 sm:w-[60px] w-[40px]  'src={logo} alt={logo} ></img>
                    <p className='sm:pl-20 pl-14 text-xl sm:text-4xl font-bold inline text-white '>Educational Background</p>
                </div>
           {/*     <div>
                    <p className=' text-2xl sm:text-4xl font-bold inline text-white '>Educational Background</p>
                </div>*/}
                <div className=' mt-4 w-full grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 text-center py-8'>

                    <div className='bg-[#0d273f] hover:scale-125 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-60 h-40 mx-auto -mt-2' src={LAURENTIAN} alt="HTML icon"/>
                        <p className='my-4 -mt-2 text-xl'> Laurentian University</p>
                        <div className="invisible group-hover:visible">
                            <ul>
                                <li className="text-sm"> Master of Engineering (M.Eng) - Engineering Science (2023-Present)
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='bg-[#0d273f] hover:scale-125 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-20 mx-auto pt-{10px}' src={IUT} alt="HTML icon"/>
                        <p className='my-4 text-xl'>Islamic University of Technology</p>
                        <div className="invisible group-hover:visible">
                            <ul>
                                <li className="text-sm">Bachelor of Science in Mechanical Engineering  (2018-2022)
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className='bg-[#0d273f] hover:scale-125 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-20 mx-auto pt-4' src={Adamzee} alt="HTML icon"/>
                        <p className='my-4 pt-9 text-xl'> Adamjee Cantonment College</p>
                        <div className="invisible group-hover:visible">
                            <ul>
                                <li className="text-sm"> Higher Secondary Certificate (2015-2017)
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};
export default Education;
