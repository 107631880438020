import React, {useEffect} from 'react';
import Nestle from '../assets/nestle.png'
import Gyre from '../assets/gyre.png'
import smartifier from '../assets/smartifier.png'
import southNorth from "../assets/Science_North_Logo.svg.png"
import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../assets/White/White/2. Prof.png";
function Experience(){
    useEffect(()=>{
        AOS.init()
    },[])
    return (
        <div name='professional' className='pt-64 pb-64 w-screen h-screen bg-[#0a192f] text-gray-300'>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000" className='  max-w-[1000px] mx-auto  flex flex-col justify-center w-full h-full'>
                <div >
                    <img className='-mb-8 sm:-mb-12 pl-2 sm:w-[60px] w-[40px]  'src={logo} alt={logo} ></img>
                    <p className='sm:pl-20 pl-14 text-xl sm:text-4xl font-bold inline text-white '>Professional Experience</p>
                </div>
                <div className=' mt-4 w-full grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6 text-center py-8'>
                    <div className='bg-[#0d273f]   group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-60  scale-125 mx-auto pt-10 pb-4' src={southNorth} alt="HTML icon"/>
                        <p className=' text-xl  pt-4'>Science North & Dynamic Earth</p>
                        <p className='text-2xl pb-4 font-bold pt-2'> Science Demonstrator  </p>
                        <p>(March 23- Present)</p>
                        <div className="invisible group-hover:visible">
                            <ul>
                                <li className="text-[10px] text-left pb-6 ">
                                    Promoting interest and understanding of geology, mining,
                                    and earth sciences through delivering interactive and engaging science demonstrations and programs to visitors of all ages, providing exceptional customer service by answering questions and providing information about mining-related exhibits and programs. Collaborating with other team members to develop and deliver new ideas to increase awareness of mining and earth sciences and conducting tours in the educational mines.

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='bg-[#0d273f]   group shadow-md shadow-[#040c16] duration-500 '>
                        <img className='w-20 mx-auto pt-8' src={Nestle} alt="HTML icon"/>
                        <p className=' text-xl  pt-4'>Nestle Bangladesh</p>
                        <p className='text-2xl pb-4 font-bold pt-2'> Manufacturing Intern  </p>
                        <p>(04/2022-07/2022)</p>
                        <div className="invisible group-hover:visible">
                            <ul>
                                <li className="text-[10px] text-left pb-6 ">Manufacturing Intern, Nestlé Bangladesh Limited:
                                    In the "Nesternship" program, I received training on manufacturing safety compliances and ISO standards. I assisted the SH&E team, conducted training sessions, and documented reports. I also analyzed factory workers' audiometric data. This program helped me develop my skills in industrial safety and compliance.

                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='px-4 pb-4 bg-[#0d273f]   group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-20 mx-auto pt-8 ' src={Gyre} alt="HTML icon"/>
                        <p className='text-xl  pt-4'>Gyre Engineering</p>
                        <p className='text-2xl pb-4 font-bold pt-2'> Engineering Analyst </p>
                        <p> (06/2021-03/2022)</p>
                        <div className="invisible group-hover:visible">
                            <ul>
                                <li className="text-[10px] pb-2 text-left">Managed research and operational activities for "BRAC Social Innovation Lab" and procured PPE equipment for BRAC's Kutupalong Camp project. I also contributed to building a data logging machine for "Icon Engineering Services Ltd." and launched the sub-brand "Kineticrafts".
                                    Additionally, I prepared and presented data-driven reports to support decision-making. </li>
                            </ul>
                        </div>
                    </div>

                    <div className='p-4 bg-[#0d273f]   group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-40 h-30 mx-auto pt-4' src={smartifier} alt="HTML icon"/>
                        <p className='pt-8 text-xl'> Smartifier Academy</p>
                        <p className='text-2xl pb-4 pt-2 font-bold'> Management Intern </p>
                        <p>(12/2018-02/2019)</p>
                        <div className="invisible group-hover:visible">
                            <ul>
                                <li className="text-[10px] text-left">Facilitated customized corporate training programs at "The Daily Star" and "CODesign," developing tailored training materials and conducting research on productivity and cognitive skills. I created compelling content, including social media videos, to support customer acquisition. Additionally, I participated in in-house
                                    training programs and received recognition as "Employee of the Month" in December 2018.</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    );
}
export default Experience;