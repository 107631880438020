import React, {useEffect} from 'react';
import 'swiper/css';
import {EffectCoverflow, EffectFade, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import photo_1 from "../assets/Final Certificates/1.jpg";
import photo_3 from "../assets/Final Certificates/ASME IUT.jpg";
import photo_4 from "../assets/Final Certificates/CSWA-AM.jpg";
import photo_5 from "../assets/Final Certificates/CSWA-SD.jpg";
import photo_6 from "../assets/Final Certificates/CSWA_page-0001.jpg";
import photo_7 from "../assets/Final Certificates/IMEOM2021 Supply Chain Competition Cert Raisul Islam Atik 01_16_2022_page-0001.jpg";
import photo_9 from "../assets/Final Certificates/Untitled-9.jpg";
import photo_11 from "../assets/Final Certificates/WHMIS.jpg"
import photo_12 from "../assets/Final Certificates/certificate_new_1.png"
import photo_13 from "../assets/Final Certificates/certificate_new_2.png"

import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../assets/White/White/5. Certs.png";
function Certificates(props) {
    useEffect(()=>{
        AOS.init()
    },[])
    return (
        <div name='certificates' className='w-screen h-full bg-[#0a192f] text-gray-300 py-4 '>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000" >
                <div className='pl-4 sm:pl-96 '>
                    <img className='-mb-8 sm:-mb-10 pl-2 w-[40px] sm:w-[50px] 'src={logo} alt={logo} ></img>
                    <p className='pl-12 sm:pl-16 text-xl sm:text-3xl sm:text-4xl font-bold inline text-white '>Certificates</p>
                </div>
                <div className='sm:px-24 px-2 pb-4 pt-16'>

                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        spaceBetween={40}
                        slidesPerView={3}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 150,
                            modifier: 2.5,
                        }}
                        pagination={{el: '.swiper-pagination', clickable: true}}
                      navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper_container"
                    >
                        <SwiperSlide>
                            <img src={photo_1} alt="slide_image"/>
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={photo_3} alt="slide_image"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={photo_4} alt="slide_image"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={photo_5} alt="slide_image"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={photo_7} alt="slide_image"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={photo_6} alt="slide_image"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={photo_9} alt="slide_image"/>
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={photo_11} alt="slide_image"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={photo_12} alt="slide_image"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={photo_13} alt="slide_image"/>
                        </SwiperSlide>

                        <div className="slider-controler">
                            <div className="swiper-button-prev slider-arrow">
                                <ion-icon name="arrow-back-outline"></ion-icon>
                            </div>
                            <div className="swiper-button-next slider-arrow">
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </Swiper>

        </div>


            </div>
        </div>);
}

export default Certificates;