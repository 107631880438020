import React, {useEffect} from 'react';
import 'swiper/css';
import {EffectCoverflow, EffectFade, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';

import photo_1 from "../assets/Skills/1600699113657.jpg"
import photo_2 from "../assets/Skills/Ansys.PNG"
import photo_3 from "../assets/Skills/helicone new sq base render 2.35.jpg"
import photo_4 from "../assets/Skills/hexagon.25.jpg"
import photo_5 from "../assets/Skills/st2.49.jpg"
import photo_6 from "../assets/Skills/smoke sensor.jpg"
import photo_7 from "../assets/Skills/tense.35.jpg"
import photo_8 from "../assets/Skills/tense.401.jpg"
import photo_9 from "../assets/Skills/tgrity.28.jpg"
import photo_10 from "../assets/Skills/the box.40.jpg"
import photo_11 from "../assets/Skills/the box.jpg"
import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../assets/White/White/7. CAD.png";
function Cadmodel(props) {
    useEffect(()=>{
        AOS.init()
    },[])
    return (
        <div name='cad' className='w-screen h-full bg-[#0a192f] text-gray-300 '>
            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000"  >
                <div className='pl-2 sm:pl-96 pb-8'>
                    <img className='-mb-8 sm:-mb-10 pl-2 w-[40px] sm:w-[50px] 'src={logo} alt={logo} ></img>
                    <p className='pl-12 sm:pl-16 text-xl sm:text-3xl sm:text-4xl font-bold inline text-white '>Engineering Design Models</p>
                </div>
                <div className='sm:px-24 pt-4'>


                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        spaceBetween={50}
                        slidesPerView={3}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2.5,
                        }}
                        pagination={{el: '.swiper-pagination', clickable: true}}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper_container"
                    >
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_1} alt="HTML icon"/>

                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_2} alt="HTML icon"/>

                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_3} alt="HTML icon"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_4} alt="HTML icon"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_5} alt="HTML icon"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_6} alt="HTML icon"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_7} alt="HTML icon"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_8} alt="HTML icon"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_9} alt="HTML icon"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='w-{120px} h-{80px}  pt-{10px}' src={photo_10} alt="HTML icon"/>
                        </SwiperSlide>

                        <div className="slider-controler">
                            <div className="swiper-button-prev slider-arrow">
                                <ion-icon name="arrow-back-outline"></ion-icon>
                            </div>
                            <div className="swiper-button-next slider-arrow">
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>

                    </Swiper></div>

            </div>
        </div>
    );
}

export default Cadmodel;