import React from 'react';
import {FaLinkedin,FaFacebookF,FaResearchgate} from "react-icons/fa";
import{TbMailFast} from "react-icons/tb";

function Footer() {
    return (
    <div className=' z-50 py-8 w-full h-[150px] bg-[#0a192f] bg-opacity-500 text-gray-400 hover:text-cyan-300' >
        <p className='text-center text-3xl '>
            Let's collaborate!
        </p>
     <div className=' flex justify-center flex-row items-center'>
         <p className='pt-2 text-center flex justify-center flex-row items-center'>
             <a
                 className='pr-4 w-full text-gray-300'
                 href='https://www.linkedin.com/in/raisulatik/' target='_blank'
                 rel="noopener noreferrer" >
               <FaLinkedin className='scale-150'>

               </FaLinkedin>
             </a>
             <a
                 className=' w-full text-gray-300'
                 href='https://www.facebook.com/Raisul.Atik' target='_blank'
                 rel="noopener noreferrer" >
               <FaFacebookF className='scale-150'>

               </FaFacebookF>
             </a>
             <a
                 className='pl-4 w-full text-gray-300'
                 href='mailto:raiuslatik15@gmail.com' target='_blank'
                 rel="noopener noreferrer" >
               <TbMailFast className='scale-150'>

               </TbMailFast>
             </a>
             <a
                 className='pl-4 w-full text-gray-300'
                 href='https://www.researchgate.net/profile/Raisul-Atik-2' target='_blank'
                 rel="noopener noreferrer" >
               <FaResearchgate className='scale-150'>

               </FaResearchgate>
             </a>
         </p>

     </div>
       {/* <p className='text-center text-xs pt-2'> raisulatik15@gmail.com
        </p>*/}
    </div>
    );
}

export default Footer;