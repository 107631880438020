import React from 'react'
import {useEffect} from "react";
import croppedProfile from '../assets/final-2.png'
import photo_5 from '../assets/Portfolio Updated Pics/5.jpg'

import {Typewriter} from 'react-simple-typewriter'
import AOS from 'aos'
import 'aos/dist/aos.css'
const Home= () => {
    useEffect(()=>{
        AOS.init()
    },[])
    const handleType = (count) => {
        // access word count number
    }
    const handleDone = () => {
    }
    return (
        <div name='home' className='w-screen pb-24 bg-[#0a192f] pl-2  '>
            {/* Container */}
            <div data-aos="fade-left" className='px-2  sm-px-0 max-w-[1000px] mx-auto w-full justify-center h-full md:gap-20 sm:gap-10 flex flex-row'>
                {/*
        <img className=" pl-px] w-[300px] mx-[200px]" src={croppedProfile} alt="Profile" />
*/}

                <div className='pt-28 basis-1/1.5 md:basis-1/2'>
                    <p>
                        <p className='text-pink-600 pb-2'>Greetings, world!  I'm </p>

                        <h1 className='font-mono  text-3xl sm:text-7xl font-bold text-[#ccd6f6]'>
                            Raisul Atik
                        </h1>
                        <h2 className='  text-3xl sm:text-5xl font-bold text-[#8892b0]'>
                            I'm an Enthusiastic <br/>{' '}
                            <span className=' text-3xl sm:text-7xl font-bold text-[#8892b0]'>
                                <Typewriter
                                    words={['Engineer', 'Researcher', 'Learner']}
                                    loop={30}
                                    cursor
                                    cursorStyle='_'
                                    typeSpeed={70}
                                    deleteSpeed={50}
                                    delaySpeed={1000}
                                    onLoopDone={handleDone}
                                    onType={handleType}
                                />
                            </span>


                        </h2>
                        <p className='invisible sm:visible text-[#8892b0] text-[12px] h-4 sm:h-auto sm:text-sm py-0 sm:py-4 max-w-[700px]'>
                            A curious and creative engineering enthusiast!
                            Whether I'm tinkering with technology or brainstorming innovative solutions, my passion for
                            engineering drives me to explore, experiment, and exceed expectations.
                            Ready to join me on this exciting journey?
                        </p>

                    </p>

                </div>
                <div className=' pt-28 sm:pt-40 pr-2 sm:pr-8 pl-2 sm:basis-1/4'>
                    <img src={photo_5}  alt="Profile" className=' sm:scale-125 scale-100'/>

                </div>
            </div>
            <p className=' visible sm:invisible text-[#8892b0] text-[12px] sm:text-sm px-2 -pt-60 max-w-[700px]'>
                A curious and creative engineering enthusiast!
                Whether I'm tinkering with technology or brainstorming innovative solutions, my passion for
                engineering drives me to explore, experiment, and exceed expectations.
                Ready to join me on this exciting journey?
            </p>

        </div>
    );
};

export default Home;