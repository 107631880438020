import Nav from "./components/nav";
import Home from "./components/Home"
import Education from "./components/Education";
import Experience from "./components/Experience"
import Publications from "./components/Publications";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Test from "./components/test";
import Photo from "./components/photo";
import Cadmodel from "./components/Cadmodel";
import {useState, useEffect} from "react";
import Preloader from "./components/preloader";
import Volunteering from "./components/Volunteering"
import Certificates from "./components/Certificates";
import Footer from "./components/footer";
import Progress from "./components/Contact";
import Remarks from "./components/remarks";
import Article from "./components/Articles";

function App() {
    const [loading, setloading] = useState(true);
    /* useEffect(() => {
         setTimeout(() => {
             setloading(false);
         }, 3000);
     })*/
    return (
        <div>
            {/* {loading==true?

                   <div>
                    <Preloader/>
                   </div>:*/}

            <div className='font-montserrat'>
                <Nav/>
                <Home/>
                <Education/>
                <Experience classname='py-40'/>
                <Volunteering/>
                <Publications/>
                <Certificates/>
                <Skills/>
                <Cadmodel/>
                <Projects/>
                <Photo/>
                <Test/>
                <Remarks/>
                <Article/>
                <Footer/>
            </div>

        </div>

    );
}

export default App;
