import React, {useEffect} from 'react';
import asme from "../assets/Vollunteer/mining.png"
import asmeAsia from "../assets/Logos/2. ASME ASIA PACIFIC.png"
import geb from "../assets/Logos/3. GEB.png"
import crosh from "../assets/Vollunteer/62e4a120-8858-4f63-b867-46d66687343d.png"
import cad from "../assets/Vollunteer/cim-logo-press-release.png"
import ewb from "../assets/Vollunteer/OSPE-2C-RGB.jpg"
import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from "../assets/White/White/3. Vol.png";
function Volunteering(props) {
    useEffect(()=>{
        AOS.init()
    },[])


    return (
        <div name='volunteering' className='pt-48 w-screen h-full bg-[#0a192f] text-gray-300'>

            {/* Container */}
            <div data-aos="fade-up" data-aos-duration="3000"  className=' mt-32 max-w-[1000px] mx-auto pr-4 flex flex-col justify-center w-full h-full'>
                <div>
                    <img className='-mb-8 sm:-mb-12 pl-2 sm:w-[60px] w-[40px]  'src={logo} alt={logo} ></img>
                    <p className='sm:pl-20 pl-14 text-xl sm:text-4xl font-bold inline text-white '>Volunteering Experience</p>
                </div>
                <div className=' mt-4 w-full grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 text-center py-8'>

                    <div className='bg-[#0d273f] hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-20 mx-auto scale-150 pt-8' src={asme} alt="HTML icon"/>
                        <p className='pt-20'>Modern Mining & Technology Sudbury (MMTS)</p>
                        <p className='text-center font-bold  text-sm'>Marketing and Communication Team</p>

                    </div>     <div className='bg-[#0d273f] hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-20 mx-auto scale-150 pt-8' src={crosh} alt="HTML icon"/>
                        <p className='pt-20'>Centre for Research in Occupational Safety and Health (CROSH)</p>
                        <p className='text-center font-bold  text-sm'>Graduate Student Member</p>

                    </div>

                    <div className='bg-[#0d273f] hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-60 h-40 mx-auto ' src={geb} alt="HTML icon"/>
                        <p>Global Entrepreneurship bootcamp</p>
                        <p className='text-center font-bold  text-sm'>International Delegate</p>
                        <div className="invisible group-hover:visible">

                        </div>

                    </div>
                    <div className='bg-[#0d273f] hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-60 h-40 mx-auto scale-50' src={cad} alt="HTML icon"/>
                        <p> CIM | Canadian Institute of Mining, Metallurgy and Petroleum</p>
                        <p className='text-center font-bold  text-sm'>Student Member</p>
                    </div>
                    <div className='bg-[#0d273f] hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-60 h-40 mx-auto scale-50' src={ewb} alt="HTML icon"/>
                        <p>  Ontario Society of Professional Engineers </p>
                        <p className='text-center font-bold  text-sm'> Student Member</p>

                    </div>
                    <div className='bg-[#0d273f] hover:scale-110 group shadow-md shadow-[#040c16] duration-500'>
                        <img className='w-60 h-40 mx-auto scale-50' src={asmeAsia} alt="HTML icon"/>
                        <p> ASME Student Regional Team (Asia-Pacific) </p>
                        <p className='text-center font-bold  text-sm'> Vice Chair</p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Volunteering;