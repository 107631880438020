import React, {useState} from "react";
import {FaBars, FaTimes, FaLinkedin, FaFacebookF} from "react-icons/fa";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {TbMailFast} from "react-icons/tb"
import Logo from "../assets/logo512.png"
import {Link} from 'react-scroll';
import Volunteering from "./Volunteering"
import Education from "./Education";
import Home from "./Home";
import Cadmodel from "./Cadmodel";
import Experience from "./Experience";
import Project from "./Projects"
import Publications from "./Publications";
import Certificate from "./Certificates"
import Photo from "./photo" ;
import Test from "./test";
import Skills from "./Skills";

import homeIcon from "../assets/White/White/0. Home.png"
import Edu from "../assets/White/White/1. Edu.png"
import prof from "../assets/White/White/2. Prof.png"
import vol from "../assets/White/White/3. Vol.png";
import pub from "../assets/White/White/4. Public.png";
import cert from "../assets/White/White/5. Certs.png";
import skill from "../assets/White/White/6. Skills.png";
import cad from "../assets/White/White/7. CAD.png";
import proj from "../assets/White/White/8. PROJ.png";
import photo from "../assets/White/White/9. Photo.png";
import acheive from "../assets/White/White/10. Acheive.png";



const Nav = () => {
    const [nav, setNav] = useState(false);
    const link = "https://drive.google.com/file/d/1MD8og1L1Kmcz-bgbjBC0-9ZtxEZ8mLQa/view?usp=sharing"
    const handleClick = () => setNav(!nav);
    return (
        <div
            className='z-50 fixed w-full sm:w-full h-[60px]  flex   bg-[#0a192f] bg-opacity-50 text-gray-300'>
            <div className='absolute visible sm:invisible left-2 pr-2 sm:left-28 top-2 pl-2'>
                <img className=' w-[40px] ' src={Logo} alt='html'></img>

            </div>
            <div className='invisible sm:visible mx-auto flex flex-row justify-start items-center'>

                    <ul className='flex flex-row justify-start items-center  '>
                        <img className='w-[60px] px-3' src={Logo} alt='html'></img>
                        <li>
                            <Link to='home' element={<Home/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={homeIcon} alt='html'></img>
                            </Link>
                        </li>
                        <li>
                            <Link to='education' element={<Education/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={Edu} alt='html'></img>

                            </Link>
                        </li>
                        <li>
                            <Link to='professional' element={<Experience/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={prof} alt='html'></img>

                            </Link>
                        </li>
                        <li>
                            <Link to='volunteering' element={<Volunteering/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={vol} alt='html'></img>

                            </Link>
                        </li>


                        <li>
                            <Link to='publications' element={<Publications/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={pub} alt='html'></img>
                            </Link>
                        </li>

                        <li>
                            <Link to='certificates' element={<Certificate/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={cert} alt='html'></img>

                            </Link>
                        </li>
                        <li>
                            <Link to='skills' element={<Skills/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={skill} alt='html'></img>

                            </Link>
                        </li>
                        <li>
                            <Link to='cad' element={<Cadmodel/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={cad} alt='html'></img>

                            </Link>
                        </li>
                        <li>
                            <Link to='projects' element={<Project/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={proj} alt='html'></img>

                            </Link>
                        </li>
                        <li>
                            <Link to='photo' element={<Photo/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={photo} alt='html'></img>

                            </Link>
                        </li>
                        <li>
                            <Link to='achievement' element={<Test/>} smooth={true} duration={500}>
                                <img className='w-[50px] px-3' src={acheive} alt='html'></img>

                            </Link>
                        </li>
                    </ul>


            </div>


            <div className='absolute right-2 top-2 '>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <button
                        className="mb-4 bg-transparent hover:bg-blue-500 text-blue-700 text-sm font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                        Resume
                    </button>
                </a>

            </div>


            <div className='invisible sm:visible lg:flex fixed flex-col top-[35%] left-0'>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#364863f3]'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='https://www.linkedin.com/in/raisulatik/' target='_blank' rel="noopener noreferrer"
                        >
                            Linkedin <FaLinkedin size={30}/>
                        </a>
                    </li>
                </ul>

                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#364863f3]'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='https://www.facebook.com/Raisul.Atik' target='_blank' rel="noopener noreferrer"
                        >
                            Facebook <FaFacebookF size={30}/>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#364863f3]'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='mailto:raiuslatik15@gmail.com' target='_blank'
                            rel="noopener noreferrer"
                        >
                            Gmail <TbMailFast size={30}/>
                        </a>
                    </li>
                </ul>
            </div>


        </div>
    );
}

export default Nav;